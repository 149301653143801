<template>
  <div class="nav-bar" :class="{'hide-element': this.$route.name === 'promo-page-events-contacts-messages' }">
    <div class="part-1" style="display: flex; flex-direction: row;"><!-- Just a flex item -->
      <div class="biletum-tabs biletum-tabs-promopage biletum-tabs-promopage-listsource">
      <span class="biletum-tab"
            @click="setTab(0)"
            :class="{'biletum-tab-current' : !this.tab}"
      >{{ $t('navigation.all') }}</span>
        <!--<span class="biletum-tab"
              @click="setTab(1)"
              :class="{'biletum-tab-current' : this.tab === 1}"
        >{{ $t('navigation.recommended') }}</span>-->
        <span class="biletum-tab"
              @click="setTab(2)"
              :class="{'biletum-tab-current' : this.tab === 2}"
        >{{ $t('navigation.my') }}</span>
        <span
          v-if="$route.meta && $route.meta.showMyMessagesTab"
          class="biletum-tab"
          @click="setTab(3)"
          :class="{'biletum-tab-current' : this.tab === 3}"
        >{{ $t('navigation.myMessages') }}</span>
        <!--      <mu-button TODO: search button-->
        <!--          icon color="primary">-->
        <!--        <iconSearch :color="'#00b6f8'"></iconSearch>-->
        <!--      </mu-button>-->
      </div>
    </div>
    <div class="pavilion-filter" v-if="$route.name === 'promo-page-events-companies'">
      <div class="wrapper">
        <div v-if="pavilions && pavilions.List.length">
          <mu-select v-model="pavilion_id"
                     :placeholder="$t('contacts.popups.createCompany.pavilions')"
                     full-width
                     style="padding: 0;"
                     class="pavilion-select"
                     solo
                     :color="'#00b6f8'"
          >
            <mu-option
              :label="$t('navigation.allHalls')"
              :value="null"
              :selected="pavilion_id === null"
            >{{ $t('navigation.all') }}
            </mu-option>
            <mu-option v-for="(pavilion, index) in pavilions.List" :key="index"
                       :label="pavilion.name"
                       :value="pavilion.id"
                       :selected="pavilion_id === pavilion.id"
            >{{ pavilion.name }}
            </mu-option>
          </mu-select>
          <!--          <div class="error-messages" v-if="errors.owner_phone">-->
          <!--            <div class="error-message">{{ $t(errors.owner_phone) }}</div>-->
          <!--          </div>-->
        </div>
        <!--        <button @click="cssSearch({target: $refs.cssSearchInput})">{{ $t('button.search') }}</button>-->
      </div>
    </div>
    <div class="biletum-search" v-if="$route.meta && $route.meta.showBiletumSearch">
      <div class="wrapper">
        <span class="icon-zoom"></span>
        <input type="text" ref="cssSearchInput" @keyup="cssSearch($event)"/>
        <button @click="cssSearch({target: $refs.cssSearchInput})">{{ $t('button.search') }}</button>
        <button @click="showFilters = !showFilters" class="filters-btn" v-if="this.eventTags && this.eventTags.length">{{ $t('button.filters') }}
        </button>
      </div>
    </div>
    <div
      class="biletum-tabs biletum-tabs-promopage biletum-tabs-promopage-listtype"
      v-if="isMapAndListMenuVisible"
    >
      <span class="biletum-tab"
            @click="setTab2(0)"
            :class="{'biletum-tab-current' : !this.tab2}"
      >{{ $t('navigation.map') }}</span>
      <span class="biletum-tab"
            @click="setTab2(1)"
            :class="{'biletum-tab-current' : this.tab2 === 1}"
      >{{ $t('navigation.list') }}</span>
    </div>
    <!--    //@selectedTags="filterTags"-->

    <!-- Tag chooser dimmer and popup -->
    <div class="category-chooser-dimmer"
         v-if="showFilters"
    ></div>

    <chooseCompanyCategories
      v-if="$route.name === 'promo-page-events-companies'
        || this.$route.name === 'promo-page-events-company'
        || this.$route.name === 'promo-profile'"
      @tagListUpdated="filterTags"
      @close="showFilters = false"
      :show="showFilters"
      :allTags="this.eventTags"
      :mode="'filter'"
      :selectedTagIds="selectedTags"
      @resetSelectedTags="resetSelectedTags"
      ref="chooseCompanyCategories"
    ></chooseCompanyCategories>
  </div>

</template>
<script>

import { mapActions, mapState } from "vuex";
// import iconSearch from '../../svg/icons/Icon-search';
import chooseCompanyCategories from "../popups/chooseCompanyCategories";

export default {
  name: 'promo-nav-bar',
  components: { chooseCompanyCategories },
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planJson: state => state.seatplanStore.planJson,
      planIsLoading: state => state.seatplanStore.isLoading,
      companiesListType: state => state.promoStore.companiesListType,
      contactListType: state => state.promoStore.contactListType,
      eventTags: state => state.eventStore.eventTags,
      pavilions: state => state.promoStore.pavilions,
    }),
    isMapAndListMenuVisible() {
      const eventId = this.$route.params.eventId;
      if (
        eventId !== this.planEventId
        || this.planIsLoading
        || !this.planJson
      ) {
        return false;
      }

      return (
        this.$route.name === 'promo-page-events-companies'
        || this.$route.name === 'promo-page-events-company'
        || this.$route.name === 'promo-profile'
      );
    }
  },
  watch: {
    gotoProp: {
      immediate: true,
      handler() {
        this.goto = this.gotoProp;
      }
    },
    companiesListType: {
      deep: true,
      handler() {
        if (this.companiesListType === 'list') {
          this.setTab2(1)
        }
      }
    },
    '$route.name': {
      immediate: true,
      handler() {
        this.pavilion_id = null;
        this.showCompaniesByPavilionId()
        if (this.$route.name === 'promo-page-events-contacts-messages') {
          this.setTab(3);
        }
        if (this.$route.name === 'promo-page-events-contacts') {
          this.setTab(0);
        }
      }
    },
    pavilion_id: {
      handler() {
        this.showCompaniesByPavilionId()
      }
    },
    selectedTags: {
      handler() {
        this.showFiltered(this.selectedTags)
      }
    }
  },
  props: {
    gotoProp: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      current: 1,
      offsetVar: 10,
      errorText: '',
      open: true,
      goto: '',
      list: {
        type: 'all'
      },
      query: {
        offset: 0,
        limit: 10
      },
      totalPages: 0,
      tab: null,
      showFilters: false,
      selectedTags: [],
      tab2: 0,
      pavilion_id: null,
    }
  },
  created() {
    if (this.$route.params.external_id) {
      this.tab2 = 1;
    }
    if (this.$route.name === 'promo-page-events-contacts-messages') {
      this.setTab(3);
    }
    this.$store.dispatch("eventStore/callEventTags", this.$route.params.eventId);
    this.$store.dispatch('promoStore/getPavilion', { eventId: this.$route.params.eventId })
  },
  methods: {
    ...mapActions('promoStore', ['promoPageListMy', 'promoPageListAll']),
    ...mapActions('contactStore', ['callContactListMy', 'callContactListAll']),

    cssSearch(ev) {
      const val = ev.target.value.trim().toLowerCase();
      const head = document.getElementsByTagName('head')[0];
      let cssSearcher = document.getElementById('cssSearcher');
      if (!cssSearcher) {
        cssSearcher = document.createElement('style');
        cssSearcher.type = 'text/css';
        cssSearcher.id = 'cssSearcher';
        cssSearcher.rel = 'stylesheet';
        head.appendChild(cssSearcher);
      }
      if (val === '') {
        cssSearcher.innerHTML = '';
      } else {
        cssSearcher.innerHTML = '.companies-wrapper .company { display: none; }';
        cssSearcher.innerHTML += '.companies-wrapper .company[companyName*="' + val + '"], .companies-wrapper .company.chosen { display: flex; }';
      }
    },

    pagination() {
      let payload = {
        event_id: this.$route.params.eventId
      };
      //TODO: if/else at 'events' or 'contacts'
      if (this.goto === 'events') {
        if (this.list.type === 'own') {
          this.promoPageListMy(payload);
        }
        if (this.list.type === 'all') {
          this.promoPageListAll(payload);
        }
      }
      if (this.goto === 'contacts') {
        if (this.list.type === 'own') {
          this.callContactListMy(payload);
        }
        if (this.list.type === 'all') {
          this.callContactListAll(payload);
        }
      }
      this.$emit('promoChooseTab', this.list.type)
    },
    setTab(val) {
      this.tab = Number(val);
      switch (this.tab) {
        case 1:
          this.list.type = 'recommended';
          this.$emit('promoChooseTab', this.list.type)
          break;
        case 2:
          this.list.type = 'own';
          this.query.offset = 0;
          this.pagination()
          this.setTab2(1)
          break;
        case 3:
          this.list.type = 'bymessages';
          this.$emit('promoChooseTab', this.list.type)
          break;
        default:
          // default case for this.tab === 0 or !this.tab
          this.list.type = 'all';
          this.query.offset = 0;
          this.pagination()
          this.setTab2(1)
      }
    },
    setTab2(val) {
      this.tab2 = Number(val);
      if (this.tab2 === 0) {
        // MAP
        this.list.type = 'map';
        this.$emit('promoChooseTab', this.list.type)
      }
      if (this.tab2 === 1) {
        // LIST
        if(this.list.type === 'all'){
          this.tab = 0;
        } else if(this.list.type === 'own'){
          this.tab = 2;
        } else {
          this.list.type = 'all'
          this.tab = 0;
        }

        this.$emit('promoChooseTab', this.list.type)
      }
    },
    filterTags(tags) {

      let tagIds = [];
      tags.map(item => {
        if (item.isSelected) {
          tagIds.push(item.id);
          tagIds.push(item.parent_id);
        }
      });
      this.selectedTags = tagIds;


    },
    showFiltered(selectedTags) {
      const head = document.getElementsByTagName('head')[0];
      let cssSearcher = document.getElementById('cssSearcher');
      let companies = []
      if (!cssSearcher) {
        cssSearcher = document.createElement('style');
        cssSearcher.type = 'text/css';
        cssSearcher.id = 'cssSearcher';
        cssSearcher.rel = 'stylesheet';
        head.appendChild(cssSearcher);
      }
      if (selectedTags.length) {
        cssSearcher.innerHTML = '';
        selectedTags.forEach(item => {
          if (item !== 0) {
            cssSearcher.innerHTML += `.companies-wrapper .company { display: none; }`;
            cssSearcher.innerHTML += '.companies-wrapper .company[tagsId*="' + item + '"], .companies-wrapper .company.chosen { display: flex; }, ';
            let nodeList = document.querySelectorAll('.companies-wrapper .company[tagsId*="' + item + '"]')
            companies.push(...nodeList)
          }
        })
      } else {
        cssSearcher.innerHTML = '';
      }
      if (companies.length === 0 && cssSearcher.innerHTML) {
        this.$store.dispatch('promoStore/companiesFoundOnRequest', true)
      } else {
        this.$store.dispatch('promoStore/companiesFoundOnRequest', false)
      }
    },
    showCompaniesByPavilionId() {
      const head = document.getElementsByTagName('head')[0];
      let cssSearcher = document.getElementById('cssSearcher');
      if (!cssSearcher) {
        cssSearcher = document.createElement('style');
        cssSearcher.type = 'text/css';
        cssSearcher.id = 'cssSearcher';
        cssSearcher.rel = 'stylesheet';
        head.appendChild(cssSearcher);
      }

      if (this.pavilion_id) {
        cssSearcher.innerHTML = '';
        cssSearcher.innerHTML += `.companies-wrapper .company { display: none; }`;
        cssSearcher.innerHTML += '.companies-wrapper .company[pavilionId*="' + this.pavilion_id + '"], .companies-wrapper .company.chosen { display: flex; }';

      } else {
        cssSearcher.innerHTML = '';
      }
    },
    resetSelectedTags(tags) {
      let categries = this.$refs.chooseCompanyCategories;
      this.selectedTags = tags.map((item, index) => {
        item.isSelected = false;
        categries.handleTagSelected({ item }, index)
      })

      categries.returnTagSelection();
      this.showFilters = false;
      setTimeout(() => {this.showFilters = true;}, 0)
      this.$store.dispatch('promoStore/companiesFoundOnRequest', false)
    }
  }
}

</script>

<style scoped lang="scss">
.mu-tabs {
  width: auto;
}

.nav-bar {
  width: 80%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1325px) {
    margin-left: 131px;
    margin-right: 0;
  }
}

.biletum-tabs {
  padding-left: 32px;

  &-promopage {
    border-radius: 10px;
    padding: 10px 34px 8px;
    background-color: #fff;
    box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
  }

  &-promopage-listsource {
    margin-left: -34px;
    padding: 10px 34px 8px;
  }

  .biletum-tab {
    cursor: pointer;
    display: inline-block;
    margin-right: 2.9rem;
    color: #000;
    position: relative;
    transition: all 0.2s;
    font-weight: 600;
    font-size: 1.3rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #00b6f8;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 0;
      height: 1px;
      background-color: transparent;
      transition: all 0.4s;
      width: 0.1%;
      content: "";
    }

    &-current {
      color: #00b6f8;

      &::after {
        background-color: #00b6f8;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1590px) {
  .nav-bar {
    .biletum-tabs-promopage-listsource {
      margin-left: 0;
    }
  }
}

.biletum-search {
  padding: 0 8px;
  display: flex;
  flex: 1;
  margin: 0 20px;
  position: relative;

  .wrapper {
    width: 100%;
    display: flex;
    justify-items: stretch;
  }

  .icon-zoom {
    flex: 1;
    max-width: 16px;
    height: 16px;
    margin-top: 12px;
    position: absolute;
    margin-left: 16px;


    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      border-style: solid;
      border-radius: 99px;
      border-width: 2px;
      border-color: #b3b3b3;
      content: "";
    }

    &::after {
      position: absolute;
      top: 11px;
      left: 11px;
      transform-origin: left center;
      width: 5px;
      height: 2px;
      border-radius: 99px;
      background-color: #b3b3b3;
      transform: rotate(45deg);
      content: "";
    }
  }

  input {
    border-style: none;
    flex: 2;
    padding: 9px 16px 8px 42px;
    outline: none;
    font-family: Point, sans-serif;
    border-radius: 9px;
    background-color: #fff;

    &:focus {
      outline: none;
    }
  }

  button {
    flex: 1;
    max-width: 118px;
    border-radius: 9px;
    margin: 0 -8px 0 0;
    background-color: $biletum-red;
    color: #fff;
    padding: 8px;
    font-size: 1.3rem;
    text-align: center;
    border-style: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: Point, sans-serif;
    padding-top: 9px;

    &:nth-of-type(1) {
      margin-right: 18px;
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &:hover,
    &:active {
      opacity: 0.75;
    }


  }

  .filters-btn {
    background-color: $biletum-light-blue;
  }
}

.category-chooser-dimmer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $z-index-company-categories-chooser-dimmer;
  background-color: rgba(0, 0, 0, 0.41);
}

.hide-element {
  opacity: 0;
}

.pavilion-select {
  background-color: #fff;
  box-shadow: 5px 5px 15px rgba(55, 84, 170, 0.1);
  border-radius: 10px;
  max-height: 32px;
  margin-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 40px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.3rem;
}
</style>
<style>
.mu-select-input {
  padding-left: 14px;

  &::placeholder {
    padding-left: 14px;
  }
}

.mu-option.is-selected .mu-item {
  color: #00b6f8;
}

.mu-option.is-selected {
  background-color: rgba(192, 192, 192, 0.2);
}

.mu-option.is-focused {
  background-color: rgba(192, 192, 192, 0.2);
}
</style>
