<template>
  <div class="component">
    <promo-head/>
    <div class="main">
      <nav-bar @promoChooseTab="promoChooseTab" :gotoProp="goto"></nav-bar>
      <div class="view-block" style="display: flex;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import promoHead from "./components/promoPage/head";
import navBar from "./components/promoPage/navBar";

export default {
  name: "promo-page",
  components: {
    promoHead,
    navBar,
  },
  computed: {
    ...mapState({
      promoPageList: state => state.promoStore.promoPageList,
      contactList: state => state.contactStore.contactList.List,
    })
  },
  watch: {
    'listSource.type': {
      immediate: true,
      handler() {
        this.$store.dispatch('promoStore/setContactListType', this.listSource.type);
      }
    },
    promoPageList: {
      deep: true,
      handler() {
        this.setPage(localStorage.getItem('promoGoto'))
      }
    },
    contactList: {
      deep: true,
      handler() {
        this.setPage(localStorage.getItem('promoGoto'))
      }
    }
  },
  mounted() {
    this.formData.event_id = this.$route.params.eventId;
    this.callPromoPage();
    this.callContact();
  },
  data() {
    return {
      contactKey: null,
      open: "send",
      favoriteCompany: false,

      isShowContact: false,
      isShowContactCompany: false,
      contactDirection: 'row',
      isShowContactDocuments: false,
      isShowContactNotes: false,

      showEvents: true,
      showContacts: false,
      tab: '',

      external_id: null,
      goto: '',

      profileNavSelected: '',


      listSource: {
        type: "all"
      },
      formData: {
        event_id: null,
        external_id: null,
        owner_phone: "+380630682880",
      }
    };
  },
  methods: {
    ...mapActions("promoStore", ["promoPageListMy", "promoPageListAll"]),
    ...mapActions("contactStore", ["callContactListMy", "callContactListAll", "callContactListByMessages"]),
    showEditUser() {
      this.showEvents = false;
      this.showContacts = false;
      this.openMap = false;
      this.openEditUser = true;
    },
    callPromoPage() {
      let data = {
        event_id: this.formData.event_id,
        external_id: this.formData.external_id
      };

      if (this.listSource.type === "own") {
        this.promoPageListMy(data);
      }
      if (this.listSource.type === "all") {
        this.promoPageListAll(data);
      }
    },
    callPromoPageList() {
      let data = {event_id: this.formData.event_id};

      if (this.listSource.type === "own") {
        this.promoPageListMy(data);
      }
      if (this.listSource.type === "all") {
        this.promoPageListAll(data);
      }
    },
    createPromoPage() {
      let data = {
        external_id: this.formData.external_id,
        owner_phone: this.formData.owner_phone
      };
      let event_id = Number(this.formData.event_id);
      this.$store.dispatch("promoStore/promoPageCreate", {data, event_id});
    },

    callContact() {
      let data = {
        event_id: this.formData.event_id,
      };
      if (this.listSource.type === "own") {
        this.callContactListMy(data);
      }
      if (this.listSource.type === "all") {
        this.callContactListAll(data);
      }
      if (this.listSource.type === "bymessages") {
        this.callContactListByMessages(data);
      }
    },
    setPage(val) {
      switch (val) {
        case 'events':
        case 'all':
        case 'own':
        case 'recommended':
          this.showEvents = true;
          this.showContacts = false;
          this.goto = 'events';
          this.openMap = false;
          break;
        case 'contacts':
          this.showContacts = true;
          this.showEvents = false;
          this.goto = val;
          this.openMap = false;
          break;
        case 'map':
          this.showContacts = false;
          this.showEvents = false;
          this.openMap = true;
          this.goto = val;
          break;
        default:
          if (this.promoPageList.length) {
            this.goto = val;
          }
          break;
      }
    },
    promoChooseTab(val) {
      this.tab = val;
      this.listSource.type = val;
      this.setPage(val);
    },
    setNavSelected(val) {
      this.profileNavSelected = val
    }
  }
};
</script>

<style scoped lang="scss">

  // TODO: check-remove this, looks old and not needed anymore
  .head-company-nav {
    text-align: left;
    font-family: "Point Regular", sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    color: #b5b5b5;
    position: relative;
    padding: 8px 0 8px 156px;
  }

  .main {
    position: relative;

    &-edituserpromo {
      height: auto !important;
    }
  }

  .view-block {
    position: relative;
    padding: 33px 90px 7rem; // TODO: put .view-block into some new, common stylesheet
  }

  .right-indent {
    margin-right: 16px;
  }

  // TODO: check-remove this, looks out of place
  .edit-user-page-descr {
    padding-left: 98px;
    padding-top: 33px;
    padding-bottom: 32px;
    min-height: 37px;
  }

</style>
